import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    { path: '/cotisation-sqc', redirect: '/calculer-ma-cotisation-sqc' },
    {
      path: '/calculer-ma-cotisation-sqc',
      name: 'calculation-start',
      component: () =>
        import(/* webpackChunkName: "client-chunk-calculation" */ '@/views/Calculation.vue'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/cotisation-sqc/:metier',
      name: 'calculation-statut',
      component: () =>
        import(/* webpackChunkName: "client-chunk-calculation" */ '@/views/Calculation.vue'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/cotisation-sqc/:metier/:statut',
      name: 'calculation-syndicat',
      component: () =>
        import(/* webpackChunkName: "client-chunk-calculation" */ '@/views/Calculation.vue'),
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/cotisation-sqc/:metier/:statut/:syndicat',
      name: 'result',
      component: () =>
        import(/* webpackChunkName: "client-chunk-result" */ '@/views/Result.vue'),
      meta: {
        authNotRequired: true
      }
    },
    { path: '/login', redirect: '/' },
    { path: '*', redirect: '/' }
  ],
  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    return { x: 0, y: 0 }
  }
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
// router.beforeEach((to, from, next) => {
//   if (
//     !(to.meta && to.meta.authNotRequired) &&
//     isNil(store.state.authentication.user)
//   ) {
//     const path =
//       store.state.authentication.user === null ? '/login' : '/check-login'
//     return next(`${path}?redirectUrl=${to.path}`)
//   }
//   next()
// })

export default router
