<template>
  <div v-if="password && password === 'SQC#2020'" class="page-wrapper">
    <!-- Home Hero -->
    <section class="hero fluid-container">
      <div class="hero-wrapper max-container">
        <div class="hero-title big-title">
          <h1>
            Construis ton futur. <br />
            Compte sur l’équipe SQC.
          </h1>
          <router-link
            :to="{ name: 'calculation-start' }"
            class="site-btn is-small"
            title="Comparer les cotisations"
            >Comparer les cotisations</router-link
          >
        </div>
      </div>

      <div class="bg">
        <img
          class="background-visual"
          src="@/assets/img/hero-home.jpg"
          alt="Employés : Syndicat Québécois de la Construction"
        />
      </div>
    </section>

    <!-- Home Video -->
    <section class="block-video fluid-container">
      <div class="block-video-wrapper max-container">
        <img
          src="@/assets/img/video-home.jpg"
          alt="Syndicat Québécois Construction"
        />
        <!-- <a class="btn-play glightbox" href="https://vimeo.com/412943670"
          ><img
            class="background-visual"
            src="@/assets/img/icon-play-white.svg"
            alt="Play video"
        /></a> -->
      </div>
    </section>

    <!-- Terms -->
    <!-- <section class="block-terms is-alternate fluid-container">
      <div class="block-terms-wrapper max-container">
        <p class="terms-text regulat-text">
          Distanciation physique ou non, le SQC est toujours près de vous. Jetez
          un œil à nos meilleures images du début 2020.
        </p>
      </div>
    </section> -->

    <!-- Home arguments -->
    <section class="block-arguments fluid-container">
      <div class="block-arguments-wrapper max-container">
        <h2 class="arguments-title big-title">
          Pourquoi rejoindre l’équipe du SQC ?
        </h2>

        <div class="arguments-list flex-grid">
          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="83px"
                class="argument-icon"
                src="@/assets/img/new-icon.svg"
                alt="L'équipe"
              />
            </div>
            <h3 class="argument-title medium-title">L’équipe</h3>
            <p class="argument-text regular-text">
              Nos représentants sont des gens de métier, comme vous. On vous
              comprend. On vous défend. C’est pourquoi on est aussi présent en
              chantier qu’aux tables de négociation.
            </p>
          </article>

          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="75px"
                class="argument-icon"
                src="@/assets/img/icon-services.svg"
                alt="Les services"
              />
            </div>
            <h3 class="argument-title medium-title">Les services</h3>
            <p class="argument-text regular-text">
              De la formation aux services juridiques gratuits en passant par la
              recherche d’emploi et la gestion de votre statut de compétences,
              on fait valoir vos droits à tous les niveaux pour vous aider à
              bâtir une carrière qui vous rendra fier.
            </p>
          </article>

          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="40px"
                class="argument-icon"
                src="@/assets/img/icon-cotisations.svg"
                alt="Les cotisations"
              />
            </div>
            <h3 class="argument-title medium-title">Les cotisations</h3>
            <p class="argument-text regular-text">
              Gérées efficacement, nos cotisations sont fixes chaque semaine,
              sans aucuns frais de l'heure travaillée et interrompues, si vous
              ne travaillez pas. Vous aurez alors accès aux services de nos
              avocats spécialisés autant en santé et sécurité qu'en relations du
              travail, en plus de bénéficier de tous nos autres services.
            </p>
          </article>

          <article class="argument-item col-4">
            <div class="argument-icon-wrapper">
              <img
                width="40px"
                class="argument-icon"
                src="@/assets/img/icon-extras.svg"
                alt="Les extra"
              />
            </div>
            <h3 class="argument-title medium-title">Avantages exclusifs</h3>
            <p class="argument-text regular-text">
              Nos membres peuvent profiter d'une panoplie d'avantages, comme des
              rabais sur notre boutique en ligne, des offres spéciales et des
              promotions exclusives.
            </p>
          </article>
        </div>
      </div>
    </section>

    <!-- Home CTA -->
    <section class="block-cta fluid-container">
      <div class="block-cta-wrapper max-container">
        <article class="block-cta-content">
          <div class="icon-circle">
            <img
              class="cta-icon"
              src="@/assets/img/icon-calculator.svg"
              alt="Calculateur"
            />
          </div>
          <h2 class="cta-title big-title">
            Devenir membre SQC, <br />
            ça vaut le coût.
          </h2>
          <router-link
            :to="{ name: 'calculation-start' }"
            class="site-btn is-small"
            title="Comparer les cotisations"
            >Comparer les cotisations</router-link
          >
        </article>

        <div class="bg">
          <img
            class="background-visual"
            src="@/assets/img/devenir-membre.jpg"
            alt="Employés : Syndicat Québécois de la Construction"
          />
        </div>
      </div>
    </section>

    <!-- Home split -->
    <section class="block-split fluid-container">
      <div class="block-split-wrapper max-container">
        <div class="flex-grid">
          <div class="split-visual">
            <img
              src="@/assets/img/sqc-video-2.png"
              alt="Scrutin Syndical 2020"
            />

            <a class="btn-play glightbox" href="https://youtu.be/F8Xb-lKOYMA"
              ><img src="@/assets/img/icon-play-white.svg" alt="Play video"
            /></a>
          </div>
          <article class="split-content">
            <h2 class="split-title big-title">
              Voter en quelques étapes faciles
            </h2>
            <p class="split-text regular-text">
              Changer votre trajectoire et améliorer votre futur, ça ne prend
              qu’une minute. Regardez notre vidéo pour passer dans la bonne
              équipe.
            </p>
            <a
              href="https://www.ccq.org/fr-CA/loi-r20/relations-travail/scrutin-syndical)"
              class="site-btn is-small"
              title="En savoir plus"
              target="_blank"
              rel="noopener noreferer"
              >En savoir plus</a
            >
          </article>
        </div>
      </div>
    </section>

    <!-- Home Newsletter -->
    <section class="block-newsletter fluid-container">
      <div class="block-newsletter-wrapper max-container">
        <img
          src="@/assets/img/icon-newsletter.svg"
          alt="Newsletter"
          class="newsletter-icon"
        />
        <h2 class="newsletter-title big-title">
          Abonnez-vous à l’infolettre <br />
          pour rester au courant.
        </h2>
        <!-- Form Metier -->
        <!-- <form class="newsletter-form" action="" >
          <div class="input-row">
            <input type="email" name="newsletter" value="" placeholder="Entrez votre courriel" required>
            <button type="submit" class="site-btn">
              <img class="background-visual" src="@/assets/img/icon-arrow-right.svg" alt="Arrow right">
            </button>
          </div>
        </form> -->
        <a
          href="https://www.sqc.ca/infolettre/"
          class="site-btn is-small"
          target="_blank"
          rel="noopener noreferer"
          title="Abonnez-vous"
          >Abonnez-vous</a
        >
      </div>

      <div class="bg">
        <img
          class="background-visual"
          src="@/assets/img/construction-chantier.jpg"
          alt="Employés : Syndicat Québécois de la Construction"
        />
      </div>
    </section>
  </div>
  <div v-else>
    <form id="passwordWrap" action="">
      <input id="password" v-model="password" type="password" name="password" />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import GLightbox from 'glightbox'

import arrowDown from '@/assets/img/arrow-down.svg'

export default {
  head() {
    return {
      title: {
        inner: "Faites partie de l'équipe Rejoignez le sqc",
      },
      meta: [
        // Global Metas OG
        { name: 'application-name', content: `${this.appTitle}` },
        {
          name: 'description',
          content: `${this.appTitle} Des représentants qui donnent leur 110 %. Engagés et présents sur le terrain, ils s'occupent de faire valoir vos droits, voient à votre santé et à votre sécurité, et sont fiers d'en faire toujours plus pour répondre à vos besoins professionnels.`,
          id: 'desc',
        },
        // Twitter OG
        { name: 'twitter:title', content: `${this.appTitle}` },
        {
          n: 'twitter:description',
          c: `${this.appTitle} Des représentants qui donnent leur 110 %. Engagés et présents sur le terrain, ils s'occupent de faire valoir vos droits, voient à votre santé et à votre sécurité, et sont fiers d'en faire toujours plus pour répondre à vos besoins professionnels.`,
        },
        // Facebook / Open Graph
        { property: 'og:title', content: `${this.appTitle}` },
        { property: 'og:site_name', content: `${this.appTitle}` },
        {
          property: 'og:description',
          content: `${this.appTitle} Des représentants qui donnent leur 110 %. Engagés et présents sur le terrain, ils s'occupent de faire valoir vos droits, voient à votre santé et à votre sécurité, et sont fiers d'en faire toujours plus pour répondre à vos besoins professionnels.`,
        },
        // Og Image
        {
          p: 'og:image',
          c: 'https://firebasestorage.googleapis.com/v0/b/syndicat-qc-construction.appspot.com/o/employes-syndicat-quebecois-de-la-construction-hero.jpg?alt=media&token=6f85cdfa-5085-4bf8-a943-65212614227d',
        },
      ],
    }
  },
  data() {
    return {
      password: null,
      arrowDown,
    }
  },
  watch: {
    password: {
      async handler(password) {
        if (password && password === 'SQC#2020') {
          // LocalStorage.setItem
          window.localStorage.setItem('sqc-password', 'granted')
        }
      },
      immediate: true,
    },
    $route: {
      async handler($route) {
        if ($route) {
          if (window.localStorage.getItem('sqc-password') === 'granted') {
            this.password = 'SQC#2020'
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const lightbox = GLightbox()
    console.log({ lightbox })
  },
  computed: mapState('app', ['appTitle']),
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/site-modules.scss';

#passwordWrap {
  width: 100%;
  height: 100vh;
  font-size: 2rem;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  background: #f5f5f5;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.5rem;
  }
}
</style>
