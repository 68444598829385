<template>
  <footer class="footer fluid-container">
    <div class="footer-wrapper max-container">
      <p class="regular-text">© 2024 SQC. Tous droits réservés.</p>
      <div class="socials">
        <a
          href="https://www.facebook.com/SyndicatQuebecoisConstruction/"
          title="Facebook"
          target="_blank"
          rel="noopened noreferer"
          ><img src="@/assets/img/icon-facebook.svg" alt="Facebook"
        /></a>
        <a
          href="https://www.instagram.com/le.sqc/"
          title="Instagram"
          target="_blank"
          rel="noopened noreferer"
          ><img src="@/assets/img/icon-instagram.svg" alt="Instagram"
        /></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.footer {
  position: relative;
  background: $dark;

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: 769px) {
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
    }

    p {
      font-size: 12px;
      margin: 0;
      padding: 15px 0;
      color: #fff;
      text-align: left;
    }

    .socials {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      padding-bottom: 15px;

      @media (min-width: 769px) {
        padding-bottom: 0;
      }

      a {
        margin: 0 5px;
        padding: 10px 18px;
        background: #466ec1;
        border-radius: 4px;

        @media (min-width: 769px) {
          margin: 0;
          border-radius: 0;
        }

        &:last-child {
          background: #e1306c;
          padding: 10px 12px;
        }
      }
    }
  }
}
</style>
