<template>
  <div id="app">
    <!-- <nav-bar ref="$nav" v-if="$route && $route.name && $route.name.indexOf('calculation') === -1"></nav-bar> -->

    <div v-if="password && password === 'SQC#2020'" class="main-wrapper">
      <router-view />
    </div>
    <div v-else>
      <form id="passwordWrap" action="">
        <input
          id="password"
          v-model="password"
          type="password"
          name="password"
        />
      </form>
    </div>

    <!-- <Footer v-if="$route && $route.name && $route.name.indexOf('calculation') === -1" /> -->

    <!-- <new-content-available-toastr
      v-if="newContentAvailable"
      class="new-content-available-toastr"
      :refreshing-app="refreshingApp"
      @refresh="serviceWorkerSkipWaiting"
    ></new-content-available-toastr>
    <apple-add-to-home-screen-modal
      v-if="showAddToHomeScreenModalForApple"
      class="apple-add-to-home-screen-modal"
      @close="closeAddToHomeScreenModalForApple(false)"
    >
    </apple-add-to-home-screen-modal> -->
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NavBar from '@/components/NavBar'
import Footer from '@/components/Footer'
// import NewContentAvailableToastr from '@/components/NewContentAvailableToastr'
// import AppleAddToHomeScreenModal from '@/components/AppleAddToHomeScreenModal'

export default {
  components: { NavBar, Footer },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp']),
  },
  data() {
    return {
      password: null,
    }
  },
  methods: {
    toogleNavBarStatus() {
      const st = document.documentElement.scrollTop

      if (st >= 150) {
        document.querySelector('body').classList.add('nav-is-sticky')
      } else {
        document.querySelector('body').classList.remove('nav-is-sticky')
      }
    },
    ...mapActions('app', [
      'closeAddToHomeScreenModalForApple',
      'serviceWorkerSkipWaiting',
    ]),
  },
  watch: {
    password: {
      async handler(password) {
        if (password && password === 'SQC#2020') {
          // LocalStorage.setItem
          window.localStorage.setItem('sqc-password', 'granted')
        }
      },
      immediate: true,
    },
    $route: {
      async handler($route) {
        if ($route) {
          if (window.localStorage.getItem('sqc-password') === 'granted') {
            this.password = 'SQC#2020'
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.toogleNavBarStatus()
    window.addEventListener('scroll', () => {
      this.toogleNavBarStatus()
    })
  },
}
</script>

<style lang="scss">
@import '@/theme/variables.scss';
@import '@/theme/base.scss';
@import '@/theme/typography.scss';
@import '@/theme/buttons.scss';
@import '@/theme/form.scss';
@import '@/theme/vendor.scss';

.nav-is-sticky {
  .navbar {
    position: fixed;
    background: $dark;
    margin: 0;
    box-shadow: 0 6px 10px 5px rgba(0, 0, 0, 0.18);

    .navbar-wrapper {
      padding: 10px 0;

      @media (min-width: 769px) {
        padding: 20px 0;
      }

      .logo {
        max-width: 125px;

        @media (min-width: 769px) {
          max-width: 145px;
        }
      }

      .nav-btn-alternate {
        display: block;

        @media (min-width: 769px) {
          display: none;
        }
      }
    }
  }
}

#passwordWrap {
  width: 100%;
  height: 100vh;
  font-size: 2rem;
  text-align: center;
  margin: 0;
  padding: 0;
  border: 0;
  background: #f5f5f5;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 100%;
    max-width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.5rem;
  }
}
</style>
