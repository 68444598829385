<template>
  <header class="navbar fluid-container">
    <div class="navbar-wrapper max-container">
      <router-link :to="{ name: 'home' }"
        ><img
          class="logo"
          src="@/assets/img/syndicat-quebecois-de-la-construction-logo.svg"
          alt="Syndicat Québécois de la Construction"
      /></router-link>
      <router-link
        :to="{ name: 'calculation-start' }"
        class="site-btn is-small"
        title="Comparer les cotisations"
        >Comparer les cotisations</router-link
      >
      <router-link
        :to="{ name: 'calculation-start' }"
        class="site-btn is-small nav-btn-alternate"
        title="Comparer les cotisations"
        >Cotisation SQC</router-link
      >
    </div>
  </header>
</template>

<script>
import firebase from 'firebase/app'
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle']),
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 99;

  transition: all 0.35s ease;

  .navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0;

    @media (min-width: 769px) {
      padding: 45px 0;
    }

    .logo {
      display: block;
      width: 100%;
      max-width: 175px;
      transition: all 0.35s ease;

      @media (min-width: 769px) {
        max-width: 275px;
      }
    }

    .site-btn {
      display: none;

      @media (min-width: 769px) {
        display: block;
      }
    }

    .nav-btn-alternate {
      display: none;
    }
  }
}
</style>
