import Vue from 'vue'

// import 'es6-promise/auto'

import VueSweetalert2 from 'vue-sweetalert2'
import VueClipboard from 'vue-clipboard2'

import App from './App.vue'
import router from './router'
import store from './store'
// import '@/misc/register-service-worker'
// import '@/misc/handle-network-status'
// import '@/firebase/init'
// import '@/firebase/authentication'
// import '@/misc/handle-apple-install-prompt'
// import 'pwacompat'

import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueSweetalert2)
Vue.use(VueClipboard)

Vue.config.productionTip = false

// Global Tweak for a toFixed that still returns a Number instead of a String
/*eslint-disable */
Number.prototype.toFixedNumber = function(x, base) {
  const pow = Math.pow(base || 10, x)
  return Math.round(this * pow) / pow
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
