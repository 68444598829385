import Vue from 'vue'
import { isNil } from 'lodash'

export default {

  /**
   * Global doAlert() toaster message using Sweetalert2
   * title
   * type
   */
  doAlert(context, { title, type }) {
    Vue.swal({
      title,
      type,
      toast: true,
      position: 'top-end',
      timer: 2500,
      showConfirmButton: false
    })
  },

  /**
   * Closes "add to home screen" modal for apple
   */
  closeAddToHomeScreenModalForApple: async ({ commit }) => {
    localStorage.setItem('addToHomeIosPromptLastDate', Date.now())
    commit('setShowAddToHomeScreenModalForApple', false)
  },

  /**
   * Trigger service worker skipWating so the new service worker can take over.
   * This will also trigger a window refresh (see /src/misc/register-service-worker.js)
   */
  serviceWorkerSkipWaiting({ state, commit }) {
    if (isNil(state.SWRegistrationForNewContent)) return

    commit('setRefreshingApp', true)
    state.SWRegistrationForNewContent.waiting.postMessage('skipWaiting')
  }
}
